import styled, { css } from 'styled-components';
import { Spacing } from 'shared/ui/atoms/spacing';

export const StyledWrapper = styled.div<{ isUpcoming: boolean }>`
    padding: 25px;
    background: white;
    border: 6px solid rgba(109, 173, 203, 0.11);
    border-radius: 14px;

    ${({ isUpcoming }) =>
        isUpcoming &&
        css`
            border: 6px solid #f8eff9;
        `}
`;

export const StyledBadge = styled(Spacing)`
    background-color: #f5e1ff; 
    border-radius: 5px;
    padding: 4px 7px;
`;
