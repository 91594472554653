import { lazy, Suspense } from 'react';
import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom';
import { RouteE } from 'shared/config/navigation';
import RootLayout from 'app/ui/root-layout';
import { AuthLayout } from 'widgets/auth-layout';
import { AuthorizedPageLayout } from 'app/ui/authorized-page-layout';
import { AuthCallback } from './callback/auth';
import HowToPage from './how-to';
import HomePage from './home';
import FaqPage from './faq';
import VideoTutorialsPage from './video-tutorials';
import HowToDetailsPage from './how-to/details';

const ProfilePage = lazy(() => import('./profile'));
const NotificationsPage = lazy(() => import('./profile/notifications'));
const SecurityPage = lazy(() => import('./profile/security'));
const ProfileMainPage = lazy(() => import('./profile/main'));
const ProfileBillingHistoryPage = lazy(() => import('./profile/billing-history'));
const ProfileSubscriptionPage = lazy(() => import('./profile/subscription'));
const EventDetailsPage = lazy(() => import('./event/details'));
const EventCreatePage = lazy(() => import('./event/create'));
const VaultPage = lazy(() => import('./vault'));

const SignInPage = lazy(() => import('./sign-in'));
const SignUpPage = lazy(() => import('./sign-up'));
const ForgotPasswordPage = lazy(() => import('./forgot-password'));
const ResetPasswordPage = lazy(() => import('./reset-password'));

const StakeholderListPage = lazy(() => import('./stakeholders/list'));
const StakeholderFormPage = lazy(() => import('./stakeholders/form'));
const GuestListPage = lazy(() => import('./guests/list'));
const GuestFormPage = lazy(() => import('./guests/form'));
const ConfirmEmailPage = lazy(() => import('./confirm-email'));

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path={RouteE.HOME} element={<RootLayout />}>
            <Route element={<AuthorizedPageLayout />}>
                <Route
                    element={<Suspense><HomePage /></Suspense>}
                    index
                />
                <Route
                    element={<Suspense><VaultPage /></Suspense>}
                    path={RouteE.VAULT}
                />
                <Route
                    element={<Suspense><FaqPage /></Suspense>}
                    path={RouteE.FAQ}
                />
                <Route
                    element={<Suspense><HowToPage /></Suspense>}
                    path={RouteE.HOW_TO}
                />
                <Route
                    element={<Suspense><HowToDetailsPage /></Suspense>}
                    path={RouteE.HOW_TO_ITEM}
                />
                <Route
                    element={<Suspense><VideoTutorialsPage /></Suspense>}
                    path={RouteE.VIDEO_TUTORIALS}
                />
                <Route
                    element={<Suspense><StakeholderListPage /></Suspense>}
                    path={RouteE.STAKEHOLDER_MANAGEMENT}
                />
                <Route
                    element={<Suspense><StakeholderFormPage /></Suspense>}
                    path={RouteE.STAKEHOLDER_ADD}
                />
                <Route
                    element={<Suspense><StakeholderFormPage /></Suspense>}
                    path={RouteE.STAKEHOLDER_EDIT}
                />
                <Route
                    element={<Suspense><GuestListPage /></Suspense>}
                    path={RouteE.GUEST_MANAGEMENT}
                />
                <Route
                    element={<Suspense><GuestFormPage /></Suspense>}
                    path={RouteE.GUEST_ADD}
                />
                <Route
                    element={<Suspense><GuestFormPage /></Suspense>}
                    path={RouteE.GUEST_MANAGEMENT_EDIT}
                />
                <Route element={<Suspense><ProfilePage /></Suspense>}>
                    <Route
                        path={RouteE.PROFILE}
                        element={<Navigate to={RouteE.PROFILE_MAIN} />}
                    />
                    <Route
                        element={<Suspense><ProfileMainPage /></Suspense>}
                        path={RouteE.PROFILE_MAIN}
                    />
                    <Route
                        element={<Suspense><NotificationsPage /></Suspense>}
                        path={RouteE.PROFILE_NOTIFICATIONS}
                    />
                    <Route
                        element={<Suspense><SecurityPage /></Suspense>}
                        path={RouteE.PROFILE_SECURITY}
                    />
                    <Route
                        element={<Suspense><ProfileBillingHistoryPage /></Suspense>}
                        path={RouteE.PROFILE_BILLING_HISTORY}
                    />
                    <Route
                        path={RouteE.PROFILE_SUBSCRIPTION}
                        element={<Suspense><ProfileSubscriptionPage /></Suspense>}
                    />
                </Route>
                <Route
                    path={RouteE.EVENT_CREATE}
                    element={<Suspense><EventCreatePage /></Suspense>}
                />
                <Route
                    path={`${RouteE.EVENT_DETAILS}/*`}
                    element={<Suspense><EventDetailsPage /></Suspense>}
                />
            </Route>
            <Route element={<AuthLayout />}>
                <Route
                    element={<Suspense><SignInPage /></Suspense>}
                    path={RouteE.SIGN_IN}
                />
                <Route
                    element={<Suspense><SignUpPage /></Suspense>}
                    path={RouteE.SIGN_UP}
                />
                <Route
                    element={<Suspense><ConfirmEmailPage /></Suspense>}
                    path={RouteE.CONFIRM_EMAIL}
                />
                <Route
                    element={<Suspense><ForgotPasswordPage /></Suspense>}
                    path={RouteE.FORGOT_PASSWORD}
                />
                <Route
                    element={<Suspense><ResetPasswordPage /></Suspense>}
                    path={RouteE.RESET_PASSWORD}
                />
            </Route>
            <Route
                element={<Suspense><AuthCallback /></Suspense>}
                path={RouteE.AUTH_CALLBACK}
            />
        </Route>
    )
);
