import React from 'react';
import { AddStakeholderPlus } from 'features/event/add-stakeholder-plus';
import { getUserName } from 'entities/user/lib/get-user-name';
import { IUser } from 'shared/api/auth/auth.types';
import { StyledList } from './styles';
import { UserAvatar } from '../../../user/ui/user-avatar';

interface IProps {
    list: IUser[];
    showAdd?: boolean;
    className?: string;
}

export const UserList: React.FC<IProps> = ({ list, className, showAdd }) => {
    return (
        <StyledList alignItems='center' className={className}>
            {list.map(user => (
                <UserAvatar
                    key={user.id}
                    title={getUserName(user)}
                    user={user}
                    size={32}
                />
            ))}
            {showAdd && <AddStakeholderPlus />}
        </StyledList>
    );
};
