import { FC, PropsWithChildren } from 'react';
import { StyledWrapper } from './styles';
import { Typography } from '../typography';
import { IProps } from './types';

export const Badge: FC<PropsWithChildren<IProps>> = ({ children, bold, bgColor, color, onClick, type = 'default' }) => (
    <StyledWrapper type={type} bgColor={bgColor} onClick={onClick} clickable={!!onClick}>
        <Typography size='labels' color={color} bold={bold}>
            {children}
        </Typography>
    </StyledWrapper>
);
